import React, { FC, ReactElement, useMemo } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { Location } from 'history';
import styled from 'styled-components/macro';
import { RouteDefinitions } from 'routes/enums/RouteDefinitions';
import { ResponsiveDeviceMaxWidth } from '../../utils/ResponsiveDevice/ResponsiveDeviceMaxWidth';
import TermsModal from '../TermsModal';
import { Link } from '@televet/kibble-ui/build/components/Link';
import { Icon } from '@televet/kibble-ui/build/components/Icon';
import { Spinner } from '@televet/kibble-ui/build/components/Spinner';
import { Flex, Center, FlexProps } from '@televet/kibble-ui/build/chakra';
import useGA from 'shared/hooks/useGA';
import { GA4Events } from 'shared/enums/GA4Events';
import { usePetParentAuthContext } from 'shared/providers/PetParentAuthProvider';
import { isMobile } from 'shared/utils/mobileCheck';

type NavigationToolbarRoute =
  | RouteDefinitions.Home
  | RouteDefinitions.Pets
  | RouteDefinitions.ConversationsList
  | RouteDefinitions.Account;

const routeLabels: Record<NavigationToolbarRoute, string> = {
  [RouteDefinitions.Home]: 'Home',
  [RouteDefinitions.Pets]: 'Pets',
  [RouteDefinitions.ConversationsList]: 'Conversations',
  [RouteDefinitions.Account]: 'Account',
};

interface INavigationToolbarProps {
  children: ReactElement;
}

const NavigationToolbar = ({ children }: INavigationToolbarProps): ReactElement | null => {
  const { gaTrack } = useGA();
  const convoRegex = new RegExp(/^\/conversations/i);
  const { isPetParentLoading } = usePetParentAuthContext();

  const currentLocation = useHistory().location;

  const shouldShowToolbar = useMemo(() => {
    const pathname = currentLocation.pathname.toLowerCase();

    if (
      pathname.includes(RouteDefinitions.Plans) ||
      pathname.includes(RouteDefinitions.Forms.replace(':formSubmissionId', '')) ||
      pathname.includes(RouteDefinitions.AutomationRun.replace(':automationRunId', '')) ||
      pathname.includes(RouteDefinitions.StripeRedirect)
    ) {
      return false;
    }

    return true;
  }, [currentLocation.pathname]);

  return isPetParentLoading ? (
    <Center h="100%">
      <Spinner />
    </Center>
  ) : (
    <>
      <TermsModal />
      <Flex direction="column" flex="1 1 auto" overflow="hidden">
        {children}
      </Flex>
      {shouldShowToolbar && (
        <ToolbarContainer>
          <StyledLink
            as={NavLink}
            to={RouteDefinitions.Home}
            onClick={(): void => gaTrack(GA4Events.NAVIGATION_HOME)}
            data-testid="bottom-nav-home"
          >
            <Icon name="house" size="xl" color="text.inactive" />
            <div>{routeLabels[RouteDefinitions.Home]}</div>
          </StyledLink>
          <StyledLink
            as={NavLink}
            to={RouteDefinitions.Pets}
            onClick={(): void => gaTrack(GA4Events.NAVIGATION_PETS)}
            data-testid="bottom-nav-pets"
          >
            <Icon name="paw" size="xl" color="text.inactive" />
            <div>{routeLabels[RouteDefinitions.Pets]}</div>
          </StyledLink>
          <StyledLink
            as={NavLink}
            isActive={(match: unknown, location: Location): boolean => convoRegex.test(location.pathname)}
            to={`${RouteDefinitions.ConversationsList}`}
            onClick={(): void => gaTrack(GA4Events.NAVIGATION_CONVERSATIONS)}
            data-testid="bottom-nav-conversations"
          >
            <Icon name="chatBubble" size="xl" color="text.inactive" />
            <div>{routeLabels[RouteDefinitions.ConversationsList]}</div>
          </StyledLink>
          <StyledLink
            as={NavLink}
            to={RouteDefinitions.Account}
            onClick={(): void => gaTrack(GA4Events.NAVIGATION_ACCOUNT)}
            data-testid="bottom-nav-account"
          >
            <Icon name="person" size="xl" color="text.inactive" />
            <div>{routeLabels[RouteDefinitions.Account]}</div>
          </StyledLink>
        </ToolbarContainer>
      )}
    </>
  );
};

// TODO: convert to kibble
const StyledLink = styled(Link)`
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  color: var(--chakra-colors-text-default);

  &.active {
    color: var(--chakra-colors-text-brand);
    background-color: var(--chakra-colors-background-primaryAlpha-pressed);
  }

  &.active svg * {
    fill: var(--chakra-colors-text-brand);
  }
`;

const toolbarMobileStyles: FlexProps = {
  height: '80px !important',
  minHeight: '80px',
  paddingBottom: '10px',
};

const toolbarWebStyles: FlexProps = {
  height: '70px !important',
  minHeight: '70px',
};

const ToolbarContainer: FC = ({ children }) => {
  return (
    <Flex
      align="center"
      justify="space-evenly"
      minHeight="65px"
      height="65px !important"
      bgColor="background.default"
      borderTop={`1px solid var(--chakra-colors-border-default)`}
      width="50%"
      margin="auto"
      zIndex={100}
      css={`
        @media ${ResponsiveDeviceMaxWidth.laptop} {
          margin: 0;
          width: 100%;
        }
      `}
      {...(isMobile() ? toolbarMobileStyles : toolbarWebStyles)}
    >
      {children}
    </Flex>
  );
};

export default NavigationToolbar;
