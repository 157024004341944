import { createContext } from 'react';
import {
  CareEnrollmentFragment,
  CarePetSelectionFragment,
  CarePetParentSelectionFragment,
  OrganizationPetParentSavedPaymentMethodFragment,
} from 'shared/types/graphql';

export type Enrollment = Pick<CareEnrollmentFragment, 'status' | 'careSubscriptionTrueUps'>;

export interface ICareEnrollmentContext {
  isEnrolledInAtLeastOnePlan: boolean;
  isEnrolledInAtLeastOnePlanWithTeletriage: boolean;
  unenrolledPets: CarePetSelectionFragment[];
  hasUnenrolledPets: boolean;
  organizationPetParent?: CarePetParentSelectionFragment | null;
  organizationPets?: CarePetSelectionFragment[] | null;
  hasOldEnrollments?: boolean;
  isOutOfDateAndPaid?: boolean;
  isClinicAcceptingEnrollments: boolean;
  paymentMethods: OrganizationPetParentSavedPaymentMethodFragment[];
  isCareEnabled: boolean;
  isEnrollmentDataLoading: boolean;
}

export default createContext<ICareEnrollmentContext>({
  isEnrolledInAtLeastOnePlan: false,
  isEnrolledInAtLeastOnePlanWithTeletriage: false,
  isClinicAcceptingEnrollments: false,
  unenrolledPets: [],
  hasUnenrolledPets: false,
  organizationPetParent: null,
  paymentMethods: [],
  isCareEnabled: false,
  isEnrollmentDataLoading: true,
});
