import { useEffect, useRef } from 'react';
import * as Sentry from '@sentry/react';
import { Mixpanel } from 'shared/utils/mixpanel';
import { env } from 'env';
import { appInfo } from 'pages/Application';
import { usePetParentAuthContext } from 'shared/providers/PetParentAuthProvider';
import usePrevious from './usePrevious';
import LogRocket from 'logrocket';

const useMixpanel = (): void => {
  const { isAuthenticated, clinic: currentClinic, petParentId } = usePetParentAuthContext();
  const hasInitialized = useRef(false);

  useEffect(() => {
    try {
      if (!(env.REACT_APP_PRODUCTION === 'true')) return;
      Mixpanel.init(env.REACT_APP_MIXPANEL_TOKEN);
    } catch (error) {
      console.error(error);
    }

    return (): void => {
      try {
        Mixpanel.reset();
      } catch (error) {
        console.error(error);
      }
    };
  }, []);

  const isAuthenticatedPrevious = usePrevious(isAuthenticated);
  useEffect(() => {
    if (!isAuthenticated && !!isAuthenticatedPrevious) {
      Mixpanel.reset();
    }
  }, [isAuthenticated, isAuthenticatedPrevious]);

  useEffect(() => {
    if (petParentId && env.REACT_APP_PRODUCTION === 'true' && !hasInitialized.current) {
      hasInitialized.current = true;
      try {
        /**
         * Mixpanel.register() adds super properties, i.e. properties that are sent with every event.
         * For details: https://docs.mixpanel.com/docs/tracking-methods/sdks/javascript#super-properties
         */
        const registerProps = {
          ...appInfo,
          clinicId: currentClinic?.id || '',
          clinicName: currentClinic?.name || '',
        };
        Mixpanel.register(registerProps);
        Mixpanel.identify(petParentId);
        Mixpanel.people.set({
          userId: petParentId,
          application: 'Care Web',
          appName: 'Care Web',
          clinicName: currentClinic?.name || 'Unknown clinic',
        });
        LogRocket.getSessionURL((sessionURL) => {
          Mixpanel.register({ ...registerProps, sessionURL });
        });
      } catch (e) {
        Sentry.captureException(e);
        console.error('Mixpanel initialization failed: ', e.message);
      }
    }
  }, [petParentId, currentClinic]);
};

export default useMixpanel;
