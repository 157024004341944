import ReactGA from 'react-ga4';
import { useCallback, useEffect } from 'react';
import { env } from 'env';
import { usePetParentAuthContext } from 'shared/providers/PetParentAuthProvider';
import { GA4Events } from 'shared/enums/GA4Events';
import LogRocket from 'logrocket';

interface Dict {
  [key: string]: string | number | boolean | undefined;
}
interface TrackingParams extends Dict {
  clinicId: string;
  isCareApp: boolean;
  petParentId: string;
  clinicName: string;
}

interface GA {
  gaTrack: (name: string, params?: Dict) => void;
  gaTrackWithLr: (name: string, params?: Dict) => void;
  setUser: (clinicPetParentId: string) => void;
  isInitialized: boolean;
}

const isDevelopment = env.NODE_ENV === 'development';

const useGA = (): GA => {
  const { clinic, petParentId } = usePetParentAuthContext();

  useEffect(() => {
    if (!ReactGA.isInitialized) {
      try {
        const gaOptions = {
          ...(isDevelopment && { debug_mode: true }),
          custom_map: {
            dimension1: 'clinicId',
            dimension2: 'isCareApp',
            dimension3: 'petParentId',
            dimension4: 'clinicName',
          },
        };

        ReactGA.initialize(env.REACT_APP_GA4_ID, { gaOptions });

        if (isDevelopment && ReactGA.isInitialized) {
          console.log(GA4Events.INIT, { id: env.REACT_APP_GA4_ID, gaOptions });
        }
      } catch (error) {
        console.log(error);
      }
    }
  }, [petParentId]);

  const gaTrack = useCallback(
    (name: string, params?: Dict): void => {
      if (isDevelopment && name.length > 40) {
        throw new Error('Event name should be less than 40 chars');
      }

      try {
        if (ReactGA.isInitialized) {
          const trackingParams: TrackingParams = {
            ...params,
            clinicId: clinic?.id || '',
            isCareApp: !!window?.ReactNativeWebView,
            petParentId,
            clinicName: clinic?.name || '',
          };
          ReactGA.event(name, trackingParams);
        }
      } catch (error) {
        console.error(error);
      }
    },
    [clinic, petParentId],
  );

  const gaTrackWithLr = useCallback(
    (name: string, params?: Dict): void => {
      gaTrack(name, params);
      if (isDevelopment) {
        console.log('LogRocket event', name, params);
      } else {
        LogRocket.track(name, params);
      }
    },
    [gaTrack],
  );

  const setUser = (clinicPetParentId: string): void => {
    ReactGA.set({ userId: clinicPetParentId });
  };

  return {
    gaTrack,
    gaTrackWithLr,
    setUser,
    isInitialized: ReactGA.isInitialized,
  };
};

export default useGA;
