import isBefore from 'date-fns/isBefore';
import subMinutes from 'date-fns/subMinutes';
import {
  CareEnrollmentFragment,
  CareSubscriptionPaymentStatus,
  CarePetSelectionFragment,
  EnrollmentStatus,
  CarePetParentSelectionFragment,
} from 'shared/types/graphql';

// How long we wait after a sign-up before we show the re-authentication flow
const NEW_SIGN_UP_WINDOW = 30;

/**
 * Checks if a pet parent's pets have any old enrollments. An enrollment is considered old
 * if it is active or if the payment has failed, and if it was created more than the specified number
 * of minutes ago. If at least one old enrollment is found, the function returns true.
 * The function allows for pending enrollments, which enables users to complete adding their
 * information for having paid.
 *
 * @param {CarePetSelectionFragment} data - An array of pets with organizationPet, carePlanEnrollments, and careAddonEnrollments.
 *
 * @returns {boolean | undefined} Returns true if at least one pet's enrollment is considered old.
 * If no old enrollments are found, or if data or relevant nested properties are undefined,
 * the function returns false or undefined respectively.
 */
export const hasOldEnrollments = (pets: CarePetSelectionFragment[] | undefined): boolean | undefined => {
  return pets?.some(({ organizationPet: op }) => {
    // Build a short list of our enrollments
    const enrollments = (op?.carePlanEnrollments || [])
      .map((cp) => ({
        id: cp.id,
        type: 'Plan',
        createdAt: new Date(cp.createdAt),
        status: cp.status,
      }))
      .filter((e) => e.status === EnrollmentStatus.Active || e.status === EnrollmentStatus.PaymentFailed);
    const now = new Date();
    const earliestToIgnore = subMinutes(now, NEW_SIGN_UP_WINDOW);
    for (let i = 0; i < enrollments.length; i++) {
      const enroll = enrollments[i];
      if (enroll.createdAt.getTime() < earliestToIgnore.getTime()) {
        return true;
      }
    }
    return false;
  });
};

/**
 * Checks if the given data is out of date, specifically if the earliest enrollment
 * date is more than a given number of minutes in the past, and if the payment status is 'Paid'.
 *
 * @param {CarePetParentSelectionFragment} data - An object containing an organizationPetParent with allCareStripeSubscriptions and
 * carePlanEnrollments.
 *
 * @returns {boolean | undefined} Returns true if the earliest enrollment is older than the given
 * number of minutes and the payment status is 'Paid'. Returns false otherwise. If data or
 * relevant nested properties are undefined, the function may also return undefined.
 */
export const isOutOfDateAndPaid = (
  organizationPetParent: CarePetParentSelectionFragment | undefined | null,
): boolean | undefined => {
  const ageLimit = subMinutes(new Date(), NEW_SIGN_UP_WINDOW); // passed in number of minutes ago
  const subscriptions = organizationPetParent?.allCareStripeSubscriptions;

  if (!subscriptions?.length) {
    return false;
  }

  const enrollments = subscriptions?.flatMap((subscription) => subscription.carePlanEnrollments);
  const earliestEnrollment = enrollments?.reduce((a, b) => {
    return new Date(a.createdAt) < new Date(b.createdAt) ? a : b;
  });
  const isOutOfDate = isBefore(new Date(earliestEnrollment?.createdAt), ageLimit);
  const isPaid = subscriptions?.every(
    (subscription) => subscription.paymentStatus === CareSubscriptionPaymentStatus.Paid,
  );
  return isOutOfDate && isPaid;
};

export const isEnrollmentCancelledButStillPaying = (
  enrollment?: Pick<CareEnrollmentFragment, 'status' | 'careSubscriptionTrueUps'>,
): boolean => {
  return (
    enrollment?.status === EnrollmentStatus.Expired &&
    !!enrollment?.careSubscriptionTrueUps.some((trueUp) => !trueUp.completedAt)
  );
};

// Considers Active or Expired with unpaid true ups as active
export const isEnrollmentConsideredActive = (
  enrollment?: Pick<CareEnrollmentFragment, 'status' | 'careSubscriptionTrueUps'>,
): boolean => {
  return enrollment?.status === EnrollmentStatus.Active || isEnrollmentCancelledButStillPaying(enrollment);
};
