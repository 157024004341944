import { useCallback } from 'react';
import { FeatureFlagKey } from 'shared/enums/FeatureFlagKeys';
import { usePetParentAuthContext } from 'shared/providers/PetParentAuthProvider';
import useCareFeatureFlag from './useCareFeatureFlag';

import { env } from 'env';
import { useCareEnrollmentContext } from 'shared/providers/CareEnrollmentProvider';

const isProduction = env.REACT_APP_PRODUCTION === 'true';

enum HotjarAction {
  Identify = 'identify',
  StateChange = 'stateChange',
}

interface UserAttributes {
  [userAttribute: string]: string | number | Date | boolean;
}

type HotjarIdentify = (action: HotjarAction, userId: string, attributes?: UserAttributes) => void;

declare global {
  interface Window {
    hj: HotjarIdentify;
  }
}

const hotjarNotDefined = (): void => {
  console.error('Hotjar is not defined');
};

const useHotjar = (): {
  identify: (userAttributes?: UserAttributes) => void;
} => {
  const { isEnrolledInAtLeastOnePlan } = useCareEnrollmentContext();
  const { petParent, petParentId, clinic } = usePetParentAuthContext();

  const { isFeatureEnabled } = useCareFeatureFlag();
  const clinicHasCareEnabled = isFeatureEnabled(FeatureFlagKey.CarePlans);

  const shouldHotjarRun = useCallback((): boolean => {
    if (!isProduction || !petParentId) return false;
    if (!window.hj) {
      hotjarNotDefined();
      return false;
    }
    return true;
  }, [petParentId]);

  const identify = useCallback(
    (userAttributes: UserAttributes = {}): void => {
      if (petParentId && clinic?.id) {
        try {
          if (!shouldHotjarRun()) return;
          const attributes: UserAttributes = {
            name: [petParent?.firstName || '', petParent?.lastName || ''].join(' ').trim(),
            email: petParent?.email || 'No email provided',
            clinicId: clinic.id,
            clinicName: clinic?.name || '',
            clinicHasCareEnabled,
            isEnrolledInAtLeastOnePlan,
            ...userAttributes,
          };
          window.hj(HotjarAction.Identify, petParentId, attributes);
        } catch (err) {
          console.log(err);
        }
      } else {
        hotjarNotDefined();
      }
    },
    [
      clinic?.id,
      clinic?.name,
      clinicHasCareEnabled,
      petParent?.firstName,
      petParent?.lastName,
      petParent?.email,
      petParentId,
      shouldHotjarRun,
      isEnrolledInAtLeastOnePlan,
    ],
  );

  return {
    identify,
  };
};

export default useHotjar;
