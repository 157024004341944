import React, { useContext, useMemo, ReactNode, useEffect } from 'react';
import jwt from 'jsonwebtoken';
import { IPetParentAuthContext } from './interfaces/IPetParentAuthContext';
import PetParentAuthContext from './context/PetParentAuthContext';
import { PersistedStateKeys } from 'shared/enums/PersistedStateKeys';
import {
  FindPetParentByTokenQuery,
  useCheckClinicCareStatusQuery,
  useFindPetParentByTokenQuery,
} from 'shared/types/graphql';
import useActiveCampaign from 'shared/hooks/useActiveCampaign';
import { useTokenContext } from '../TokenProvider';
import { ApolloClient, InMemoryCache, useApolloClient } from '@apollo/client';
import { SessionStorageKeys } from 'shared/enums/SessionStorageKeys';
import useAppCommunication from 'shared/hooks/useAppCommunication';
import * as Sentry from '@sentry/react';
import useClinicListResync from 'shared/hooks/useClinicListResync';

export const usePetParentAuthContext = (): IPetParentAuthContext =>
  useContext<IPetParentAuthContext>(PetParentAuthContext);

interface IPetParentAuthContextProps {
  onIdentifyPetParent: (petParent: FindPetParentByTokenQuery['findClinicPetParentByToken']) => void;
  children: ReactNode;
}

const PetParentAuthProvider = ({ children, onIdentifyPetParent }: IPetParentAuthContextProps): JSX.Element | null => {
  const { token, redeemCode, logout, getLiveToken } = useTokenContext();
  const location = window.location.search;
  const isImpersonating = sessionStorage.getItem(SessionStorageKeys.IsImpersonating) === 'true';

  const params = useMemo(() => new URLSearchParams(location), [location]);
  const client = useApolloClient();

  const clinicId = useMemo(() => {
    if (params.get('clinicId')) {
      return params.get('clinicId');
    }
  }, [params]);

  useMemo(() => {
    if (params.has('impersonate') && params.get('impersonate') !== 'false') {
      sessionStorage.setItem(SessionStorageKeys.IsImpersonating, 'true');
    }

    if (
      params.has('expired_pet_parent_token') &&
      params.get('expired_pet_parent_token') !== 'false' &&
      getLiveToken()
    ) {
      logout({ clinicId: clinicId || '' });
    }
  }, [params, logout, clinicId, getLiveToken]);

  const petParentId = useMemo(() => {
    if (!token) return '';
    const payload = jwt.decode(token) as Record<string, string>;
    return payload?.clinicPetParentId || '';
  }, [token]);

  useEffect(() => {
    return localStorage.setItem(PersistedStateKeys.CachedToken, token || '');
  }, [token]);

  const { data: clinicCareStatusData } = useCheckClinicCareStatusQuery({
    variables: {
      data: {
        clinicId,
        token,
      },
    },
    skip: !(clinicId || token),
    context: {
      headers: {
        'skip-context': 'true',
      },
    },
    onError: (error) => {
      if (error.message === 'Not Authorized') {
        logout({ clinicId: clinicId || '' });
        return;
      }
    },
  });

  const code = params.get('code');
  useEffect(() => {
    if (code && code !== localStorage.getItem(PersistedStateKeys.CachedLastUsedCode)) {
      redeemCode(code, client as ApolloClient<InMemoryCache>);
      localStorage.setItem(PersistedStateKeys.CachedLastUsedCode, code);
    }
  }, [client, redeemCode, code, logout, clinicId]);

  const { identifyContact } = useActiveCampaign();
  const { setClinicPetParentId } = useClinicListResync();

  // DO NOT FETCH PETS FROM THIS QUERY
  const { data: petParent, loading: isPetParentLoading, refetch: refetchPetParent } = useFindPetParentByTokenQuery({
    skip: !token || !petParentId,
    onCompleted: ({ findClinicPetParentByToken }) => {
      const _data = findClinicPetParentByToken;
      if (_data?.isDeleted || !_data?.isActive || !_data.clinic?.isClinicActive) {
        logout({ clinicId: clinicId || '' });
        return;
      }

      if (_data?.email) {
        identifyContact(_data.email);
      }
      onIdentifyPetParent(_data);
      setClinicPetParentId(_data?.id || '');
    },
    onError: (error) => {
      Sentry.captureException(error);
      if (error.message === 'Not Authorized') {
        logout({ clinicId: clinicId || '' });
        return;
      }
    },
  });

  // TODO: 86dvu4876 - It's not clear if this should live here or the care enrollment provider
  const clinic = useMemo(() => {
    const tokenClinic = clinicCareStatusData?.checkClinicCareStatus?.tokenClinic;
    const idClinic = clinicCareStatusData?.checkClinicCareStatus?.idClinic;
    return idClinic || tokenClinic || undefined;
  }, [clinicCareStatusData]);

  const featureFlags = useMemo(() => {
    const featureFlags = clinic?.enabledFeatureFlags;
    if (featureFlags) {
      return featureFlags;
    } else {
      return [];
    }
  }, [clinic?.enabledFeatureFlags]);

  const hasCustomizableFees =
    petParent?.findClinicPetParentByToken?.clinic?.clinicSetting?.hasCustomizableFees || false;

  useAppCommunication(petParentId);

  return (
    <PetParentAuthContext.Provider
      value={{
        token: token || '',
        isAuthenticated: !!token,
        isImpersonating,
        clinic,
        petParentId,
        featureFlags,
        petParent: petParent?.findClinicPetParentByToken,
        isPetParentLoading,
        refetchPetParent,
        clinicHasCustomizableFees: hasCustomizableFees,
      }}
    >
      {children}
    </PetParentAuthContext.Provider>
  );
};

export default PetParentAuthProvider;
