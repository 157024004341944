import { createContext } from 'react';
import { IPetParentAuthContext } from '../interfaces/IPetParentAuthContext';

export default createContext<IPetParentAuthContext>({
  token: undefined,
  isAuthenticated: false,
  isImpersonating: false,
  clinic: undefined,
  petParentId: '',
  featureFlags: [] || undefined,
  isPetParentLoading: true,
  refetchPetParent: () => {
    console.log('NOT INITALIZED YET');
  },
  clinicHasCustomizableFees: false,
});
