import React, { useContext, useMemo, ReactNode } from 'react';
import CareEnrollmentContext, { ICareEnrollmentContext } from './context/CareEnrollmentContext';
import {
  EnrollmentStatus,
  CareProgramLaunchStatus,
  useGetCarePlanPetsDataQuery,
  useGetCarePlanPetParentDataQuery,
} from 'shared/types/graphql';
import { hasOldEnrollments, isOutOfDateAndPaid, isEnrollmentConsideredActive } from './utils';
import { useTokenContext } from '../TokenProvider';
import { usePetParentAuthContext } from '../PetParentAuthProvider';
import { uniqBy } from 'lodash';

export const useCareEnrollmentContext = (): ICareEnrollmentContext =>
  useContext<ICareEnrollmentContext>(CareEnrollmentContext);

interface ICareEnrollmentContextProps {
  children: ReactNode;
}

const CareEnrollmentProvider = ({ children }: ICareEnrollmentContextProps): JSX.Element | null => {
  const { token } = useTokenContext();
  const { clinic, petParentId } = usePetParentAuthContext();
  // TODO: 86dvu4876 - We should evaluate the data we are fetching and only fetch what is absolutely necessary
  const { data: petParentCarePlanData, loading: isPetParentCarePlanDataLoading } = useGetCarePlanPetParentDataQuery({
    variables: {
      where: {
        id: petParentId,
      },
    },
    skip: !token,
  });
  // TODO: 86dvu4876 - Maybe make this a lazy query and only call when abolutely necessary
  // Either way, we should evaluate the data we are fetching and only fetch what is absolutely necessary
  const { data: petCarePlanData, loading: isPetCarePlanDataLoading } = useGetCarePlanPetsDataQuery({
    variables: {
      where: {
        id: petParentId,
      },
    },
    skip: !token,
  });

  const organizationPetParent = petParentCarePlanData?.findUniqueClinicPetParent?.organizationPetParent;
  const organizationPets = petCarePlanData?.findUniqueClinicPetParent?.pets;

  const subscriptions = useMemo(() => organizationPetParent?.allCareStripeSubscriptions || [], [organizationPetParent]);

  const activeEnrollments = useMemo(() => {
    const enrollments = subscriptions.flatMap((subscription) => subscription.carePlanEnrollments);
    return enrollments.filter((enrollment) => enrollment.status === EnrollmentStatus.Active);
  }, [subscriptions]);

  const isEnrolledInAtLeastOnePlan = useMemo(() => {
    if (isPetParentCarePlanDataLoading) return false;
    if (!subscriptions.length) {
      return false;
    }

    return !!activeEnrollments.length;
  }, [activeEnrollments, isPetParentCarePlanDataLoading, subscriptions.length]);

  const isEnrolledInAtLeastOnePlanWithTeletriage = useMemo(() => {
    if (isPetParentCarePlanDataLoading) return false;
    if (!subscriptions.length) {
      return false;
    }
    if (!activeEnrollments.length) {
      return false;
    }

    const carePlanBenefits = activeEnrollments.flatMap((enrollment) => enrollment.plan?.carePlanBenefits);
    const careBenefits = uniqBy(
      carePlanBenefits.flatMap((benefit) => benefit.careBenefit),
      'id',
    );

    return !!careBenefits.some((benefit) => benefit?.type === 'Teletriage');
  }, [activeEnrollments, isPetParentCarePlanDataLoading, subscriptions.length]);

  const unenrolledPets = useMemo(() => {
    if (isPetParentCarePlanDataLoading || !organizationPets) return [];

    return organizationPets?.filter((pet) => {
      if (!subscriptions?.length) {
        return pet;
      }

      const activeEnrollment = pet.organizationPet?.carePlanEnrollments.filter((enrollment) =>
        isEnrollmentConsideredActive(enrollment),
      );
      return activeEnrollment?.length ? undefined : pet;
    });
  }, [isPetParentCarePlanDataLoading, organizationPets, subscriptions?.length]);

  const hasUnenrolledPets = useMemo(() => !!unenrolledPets && !!unenrolledPets.length, [unenrolledPets]);

  //  This function was kept defined outside of the provider so that it can be imported in the unit test files for testing purposes
  const _hasOldEnrollments = useMemo(() => {
    if (isPetCarePlanDataLoading || !organizationPets) return false;
    return hasOldEnrollments(organizationPets);
  }, [organizationPets, isPetCarePlanDataLoading]);

  //  This function was kept defined outside of the provider so that it can be imported in the unit test files for testing purposes
  const _isOutOfDateAndPaid = useMemo(() => {
    if (isPetParentCarePlanDataLoading) return false;
    return isOutOfDateAndPaid(organizationPetParent);
  }, [organizationPetParent, isPetParentCarePlanDataLoading]);

  const isClinicAcceptingEnrollments = useMemo((): boolean => {
    const launchStatus = petParentCarePlanData?.findUniqueClinicPetParent?.clinic?.carePlanProviderGroup?.launchStatus;
    return launchStatus !== CareProgramLaunchStatus.Churning && launchStatus !== CareProgramLaunchStatus.Archived;
  }, [petParentCarePlanData?.findUniqueClinicPetParent?.clinic?.carePlanProviderGroup?.launchStatus]);

  const paymentMethods =
    petParentCarePlanData?.findUniqueClinicPetParent?.organizationPetParent?.stripeCustomer?.paymentMethods || [];

  const isCareEnabled = useMemo(() => !!clinic?.careEnabled, [clinic]);

  return (
    <CareEnrollmentContext.Provider
      value={{
        isEnrolledInAtLeastOnePlan,
        isEnrolledInAtLeastOnePlanWithTeletriage,
        unenrolledPets,
        hasUnenrolledPets,
        organizationPetParent,
        organizationPets,
        hasOldEnrollments: _hasOldEnrollments,
        isOutOfDateAndPaid: _isOutOfDateAndPaid,
        isClinicAcceptingEnrollments,
        paymentMethods,
        isCareEnabled: isCareEnabled || false,
        isEnrollmentDataLoading: isPetCarePlanDataLoading || isPetParentCarePlanDataLoading,
      }}
    >
      {children}
    </CareEnrollmentContext.Provider>
  );
};

export default CareEnrollmentProvider;
